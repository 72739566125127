<template>
    <PageApp namePage="rel-agenda-clientes" title="Relatório de clientes" subtitle="Informe um cliente e visualize os seus agendamentos">
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Parâmetros</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-text>
                            <v-select v-model="pProfissional" :items="listProfissionais" label="Profissional" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="pCliente" label="Cliente" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="pDataIni" label="Data inicial" v-mask="'##/##/####'" :color="consts.corNeutraEscura" dense />
                            <v-text-field v-model="pDataFim" label="Data final" v-mask="'##/##/####'" :color="consts.corNeutraEscura" dense />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="carregarRelatorio" :color="consts.corNeutraEscura" outlined dark>
                                Carregar relatório
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Relatório</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-rel-agenda-clientes" hide-default-footer :headers="colunasRel" :items="dadosRel"
                     no-data-text="Nenhum registro encontrado" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                        <template v-slot:[`item.agd_data_hora`]="{ item }">
                            {{ functions.getDataHoraFormatada(item.agd_data_hora, true) }}
                        </template>
                    </v-data-table>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

//Essa tela não pode "herdar" do template PageRelatorio pois existe um novo campo "Cliente" que o usuário precisa preencher!

export default {
    name: 'RelAgendaClientes',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            panels: [0],
            pProfissional: -1,
            pCliente: '',
            pDataIni: '',
            pDataFim: '',
            listProfissionais: [],
            dadosRel: [],
            colunasRel: [
                {
                    text: 'Cliente',
                    value: 'agd_cliente'
                },
                {
                    text: 'Data/Hora',
                    value: 'agd_data_hora'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                }
            ]
        }
    },
    methods: {
        carregarRelatorio() {
            this.dadosRel = []

            axios.put(`${consts.baseApiUrl}/rel_agenda_clientes`, {
                    profissional: this.pProfissional,
                    cliente: this.pCliente,
                    data_ini: this.pDataIni,
                    data_fim: this.pDataFim
                })
                .then(r => {
                    this.dadosRel = r.data.data
                    this.panels = [1]
                })
                .catch(err => {
                    functions.showError(err)
                })
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            if (this.$store.state.profissional.emp_rel_agenda != 'S') {
                this.$router.push({
                    name: 'no-permission'
                })
            }
            else {
                this.pDataIni = functions.getPrimeiroDiaMes()
                this.pDataFim = functions.getUltimoDiaMes()

                if (this.$store.state.profissional.prf_admin == 'S') {
                    axios.get(`${consts.baseApiUrl}/profissionais`)
                        .then(r => {
                            r.data.data.forEach(el => {
                                this.listProfissionais.push({
                                    text: el.prf_nome,
                                    value: el.prf_id
                                })
                            })
                        })
                        .catch(functions.showError)
                } else {
                    this.listProfissionais = [
                        {
                            text: this.$store.state.profissional.prf_nome,
                            value: this.$store.state.profissional.prf_id
                        }
                    ]
                }
            }
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>