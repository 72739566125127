<template>
    <PageApp namePage="cad-horarios" title="Cadastro de horários" subtitle="Cadastro dos horários do profissional da empresa">
        
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Dias da semana: {{ getDescricaoDia(diaSelec) }}</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-radio-group v-model="diaSelec" dense>
                        <v-radio v-for="(dia, i) in dias" :key="i" :label="dia.text" :value="dia.value" /> 
                    </v-radio-group>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">

                    <strong>Horários: {{ getDescricaoDia(diaSelec) }}</strong>
                    <v-spacer></v-spacer>
                    <v-btn @click="mostrarCriarHorario()" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>

                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-listagem" hide-default-footer :headers="colunas" :items="getHorariosDia(diaSelec)" 
                     no-data-text="Nenhum horário cadastrado para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                        <template v-slot:[`item.btnActions`]="{ item }">
                            <v-btn class="mr-2" @click="mostrarEditar(item)" :color="consts.corNeutraClara" x-small dark>Editar</v-btn>
                            <v-btn @click="remover(item)" :color="consts.corNeutraEscura" x-small dark>Remover</v-btn>
                        </template>
                    </v-data-table>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Ações</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-row justify="center" class="mt-1 mb-2">
                        <v-btn @click="gerarHorarios = true" :color="consts.corNeutraClara" small dark>Gerar registros automaticamente</v-btn>
                    </v-row>
                    <v-row justify="center" class="mb-2">
                        <v-btn class="mr-3" @click="confirmaSave = true" :color="consts.corNeutraClara" small dark>Salvar</v-btn>
                        <v-btn @click="confirmaCancel = true" :color="consts.corNeutraEscura" small dark>Cancelar todas as alterações</v-btn>
                    </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <!-- Dialog para criar um novo horário -->
        <v-dialog v-model="criarHorario" max-width="400">
            <v-card>
                <v-card-title class="mb-3">Novo horário</v-card-title>
                <v-card-text>
                    
                    <v-row class="justify-center">
                        <v-time-picker v-model="newHorario" format="24hr" :color="consts.corNeutraEscura"
                         :header-color="consts.corNeutraClara" use-seconds small />
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="novoHorario(newHorario)" :color="consts.corNeutraEscura" text>
                        Salvar
                    </v-btn>
                    <v-btn @click="criarHorario = false" :color="consts.corNeutraEscura" text>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog pra confirmar o cancelamento -->
        <v-dialog v-model="confirmaCancel" max-width="300">
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Confirma o cancelamento de todas as alterações realizadas?
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelarAlteracoes()" :color="consts.corNeutraEscura" text>
                        Sim
                    </v-btn>
                    <v-btn @click="confirmaCancel = false" :color="consts.corNeutraEscura" text>
                        Não
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog pra confirmar a ação de salvar os registros -->
        <v-dialog v-model="confirmaSave" max-width="300">
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Deseja salvar todos os registros?
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="salvar()" :color="consts.corNeutraEscura" text>
                        Sim
                    </v-btn>
                    <v-btn @click="confirmaSave = false" :color="consts.corNeutraEscura" text>
                        Não
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog pra alterar o horário de um registro -->
        <v-dialog v-model="alteraHorario" max-width="450">
            <v-card>
                <v-card-title class="mb-3">Alteração de horário</v-card-title>
                <v-card-text>
                    
                    <v-row class="justify-center">
                        <v-time-picker v-model="horarioAlterar.hor_hora" format="24hr" :color="consts.corNeutraEscura"
                         :header-color="consts.corNeutraClara" use-seconds small />
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="editar(horarioAlterar)" :color="consts.corNeutraEscura" text>
                        Salvar
                    </v-btn>
                    <v-btn @click="alteraHorario = false" :color="consts.corNeutraEscura" text>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog pra gerar os registros automaticamente -->
        <v-dialog v-model="gerarHorarios" max-width="450">
            <v-card>
                <v-card-title class="mb-3">Gerar horários</v-card-title>
                <v-card-text>
                    
                    <v-checkbox v-model="diasCriar.seg" label="Segunda" :color="consts.corNeutraEscura" dense />
                    <v-checkbox v-model="diasCriar.ter" label="Terça" :color="consts.corNeutraEscura" dense />
                    <v-checkbox v-model="diasCriar.qua" label="Quarta" :color="consts.corNeutraEscura" dense />
                    <v-checkbox v-model="diasCriar.qui" label="Quinta" :color="consts.corNeutraEscura" dense />
                    <v-checkbox v-model="diasCriar.sex" label="Sexta" :color="consts.corNeutraEscura" dense />
                    <v-checkbox v-model="diasCriar.sab" label="Sábado" :color="consts.corNeutraEscura" dense />
                    <v-checkbox v-model="diasCriar.dom" label="Domingo" :color="consts.corNeutraEscura" dense />
                    <v-text-field class="mt-4" v-model="horaIni" label="Hora inicial" type="time" :color="consts.corNeutraEscura" dense />
                    <v-text-field v-model="horaFim" label="Hora final" type="time" :color="consts.corNeutraEscura" dense />
                    <v-text-field class="mt-4" v-model="pausaIni" label="Intervalo inicial" type="time" :color="consts.corNeutraEscura" dense />
                    <v-text-field v-model="pausaFim" label="Intervalo final" type="time" :color="consts.corNeutraEscura" dense />
                    <v-text-field class="mt-4" v-model="intervalo" label="Intervalo (minutos)" type="number" :color="consts.corNeutraEscura" dense />

                </v-card-text>
                <v-card-text>
                    Ao salvar, você aceita que ele vai excluir todos os registros existentes e criá-los novamente.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="gerarRegistros(diasCriar, horaIni, horaFim, intervalo, pausaIni, pausaFim)" :color="consts.corNeutraEscura" text>
                        Salvar
                    </v-btn>
                    <v-btn @click="gerarHorarios = false" :color="consts.corNeutraEscura" text>
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import functions from '@/globalFiles/functions'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'CadHorarios',
    components: {
        PageApp
    },
    data() {
        return {
            functions,
            consts,
            criarHorario: false,
            confirmaCancel: false,
            confirmaSave: false,
            alteraHorario: false,
            gerarHorarios: false,
            panels: [0, 1, 2],
            diaSelec: 'SEG',
            newHorario: '00:00:00',
            horaIni: '08:00:00',
            horaFim: '18:00:00',
            pausaIni: '12:00:00',
            pausaFim: '13:00:00',
            intervalo: '15',
            horarios: [],
            horarioAlterar: {},
            diasCriar: {
                seg: true,
                ter: true,
                qua: true,
                qui: true,
                sex: true,
                sab: false,
                dom: false
            },
            dias: [
                {
                    text: 'Segunda',
                    value: 'SEG'
                },
                {
                    text: 'Terça',
                    value: 'TER'
                },
                {
                    text: 'Quarta',
                    value: 'QUA'
                },
                {
                    text: 'Quinta',
                    value: 'QUI'
                },
                {
                    text: 'Sexta',
                    value: 'SEX'
                },
                {
                    text: 'Sábado',
                    value: 'SAB'
                },
                {
                    text: 'Domingo',
                    value: 'DOM'
                },
            ],
            colunas: [
                {
                    text: 'Hora',
                    value: 'hor_hora'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        cancelarAlteracoes() {
            this.confirmaCancel = false
            this.loadHorarios()
        },
        editar(horario) {
            this.alteraHorario = false

            if (horario.hor_hora != horario.horaOriginal) {

                let idx = this.horarios.findIndex(filter =>
                    filter.hor_tipo == horario.hor_tipo &&
                    filter.hor_hora == horario.hor_hora
                )

                //Se já existe um horário com o novo alterado, apenas exclui o antigo
                if (idx != -1) {
                    this.remover({
                        hor_tipo: horario.hor_tipo,
                        hor_hora: horario.horaOriginal
                    })
                } else {
                    idx = this.horarios.findIndex(filter =>
                        filter.hor_tipo == horario.hor_tipo &&
                        filter.hor_hora == horario.horaOriginal
                    )

                    if (idx != -1) {
                        this.horarios[idx].hor_hora = horario.hor_hora
                    }
                }
            }
        },
        gerarRegistros(dias, horaIni, horaFim, intervalo, pausaIni, pausaFim) {
            const aDias = []
            
            if (dias.seg) {
                aDias.push('SEG')
            }
            if (dias.ter) {
                aDias.push('TER')
            }
            if (dias.qua) {
                aDias.push('QUA')
            }
            if (dias.qui) {
                aDias.push('QUI')
            }
            if (dias.sex) {
                aDias.push('SEX')
            }
            if (dias.sab) {
                aDias.push('SAB')
            }
            if (dias.dom) {
                aDias.push('DOM')
            }

            if (aDias.length > 0) {
                const dataBase = '2000-01-01'
                const dIni = new Date(`${dataBase}T${horaIni}`)
                const dFim = new Date(`${dataBase}T${horaFim}`)

                if (dIni > dFim) {
                    functions.showError('Hora inicial maior que a hora final')
                } else if (intervalo <= 0) {
                    functions.showError('Intervalo deve ser maior que zero.')
                } else {
                    try {
                        const pIni = new Date(`${dataBase}T${pausaIni}`)
                        const pFim = new Date(`${dataBase}T${pausaFim}`)

                        let considerarPausa = true
                        if (pFim <= pIni || pIni <= dIni || pFim >= dFim) {
                            considerarPausa = false
                        }

                        this.horarios = []
    
                        aDias.forEach(diaSemana => {
                            let vIni = new Date(dIni)
    
                            while (vIni < dFim) {
    
                                let registrar = false
                                if (!considerarPausa) {
                                    registrar = true
                                } else if (!(vIni >= pIni && vIni < pFim)) {
                                    registrar = true
                                }
    
                                if (registrar) {
                                    this.horarios.push({
                                        hor_id: null,
                                        prf_id: this.$store.state.profissional.prf_id,
                                        hor_tipo: diaSemana,
                                        hor_hora: `${functions.getHHFormat(vIni)}:${functions.getMMFormat(vIni)}:${functions.getSSFormat(vIni)}`
                                    })
                                }

                                vIni.setTime(vIni.getTime() + (intervalo * 60 * 1000))
                            }
                        })

                        this.gerarHorarios = false
                        functions.showSuccess('Horários criados com sucesso.')
                    } catch(e) {
                        functions.showError('Erro na geração dos horários: ' + e)
                    }
                }
            } else {
                functions.showError('Nenhum dia da semana selecionado.')
            }
        },
        getDescricaoDia(d) {
            const result = this.dias.filter(obj => {
                return obj.value == d
            })

            return result[0].text
        },
        getHorariosDia(d) {
            return this.horarios.filter(obj => {
                return obj.hor_tipo == d
            }).sort((a, b) => {
                return a.hor_hora < b.hor_hora ? -1 : a.hor_hora > b.hor_hora ? 1 : 0
            })
        },
        loadHorarios() {
            this.horarios = []
            axios.get(`${consts.baseApiUrl}/horarios`)
                .then(r => {
                    this.horarios = r.data.data
                })
                .catch(functions.showError)
        },
        mostrarCriarHorario() {
            this.newHorario = '00:00:00'
            this.criarHorario = true
        },
        mostrarEditar(horario) {
            this.alteraHorario = true
            this.horarioAlterar = {
                ...horario,
                horaOriginal: horario.hor_hora
            }
        },
        novoHorario(horario) {
            this.criarHorario = false

            this.horarios.push({
                hor_id: null,
                prf_id: this.$store.state.profissional.prf_id,
                hor_tipo: this.diaSelec,
                hor_hora: horario
            })
        },
        remover(horario) {
            const idx = this.horarios.findIndex(filter =>
                filter.hor_tipo == horario.hor_tipo &&
                filter.hor_hora == horario.hor_hora
            )

            if (idx != -1) {
                this.horarios.splice(idx, 1)
            }
        },
        salvar() {
            /* Modelo do json que deve ser enviado ao backend pra criar/atualizar os horários:
            {
                prf_id: ...,
                registros: [
                    {
                        hor_tipo: ...,
                        hor_hora: [
                            ...,
                        ]
                    },
                    ...
                ]
            }
            */

            this.confirmaSave = false
            
            if (this.horarios.length > 0) {

                let hrBody = []

                this.dias.forEach(d => {

                    let aAux = []

                    this.horarios.filter(obj => {
                        return obj.hor_tipo == d.value
                    }).forEach(hr => {
                        aAux.push(hr.hor_hora)
                    })

                    if (aAux.length > 0) {
                        hrBody.push({
                            hor_tipo: d.value,
                            hor_hora: aAux
                        })
                    }
                })

                //Precisa ter todos os dias da semana, pra excluir um dia completo caso tudo for excluído
                if (!hrBody.find(el => el.hor_tipo == 'SEG')) {
                    hrBody.push({ hor_tipo: 'SEG', hor_hora: [] })
                }
                if (!hrBody.find(el => el.hor_tipo == 'TER')) {
                    hrBody.push({ hor_tipo: 'TER', hor_hora: [] })
                }
                if (!hrBody.find(el => el.hor_tipo == 'QUA')) {
                    hrBody.push({ hor_tipo: 'QUA', hor_hora: [] })
                }
                if (!hrBody.find(el => el.hor_tipo == 'QUI')) {
                    hrBody.push({ hor_tipo: 'QUI', hor_hora: [] })
                }
                if (!hrBody.find(el => el.hor_tipo == 'SEX')) {
                    hrBody.push({ hor_tipo: 'SEX', hor_hora: [] })
                }
                if (!hrBody.find(el => el.hor_tipo == 'SAB')) {
                    hrBody.push({ hor_tipo: 'SAB', hor_hora: [] })
                }
                if (!hrBody.find(el => el.hor_tipo == 'DOM')) {
                    hrBody.push({ hor_tipo: 'DOM', hor_hora: [] })
                }

                hrBody = {
                    prf_id: this.$store.state.profissional.prf_id,
                    registros: hrBody
                }
                
                axios.post(`${consts.baseApiUrl}/horarios`, hrBody)
                    .then(() => {
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            } else {
                functions.showError('Nenhum horário informado.')
            }
        },
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            this.loadHorarios()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>
    .tabela-listagem tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .03);
    }
</style>