<template>
    <PageApp namePage="config-page" title="Configurações do sistema" subtitle="Visualize e atualiza as configurações do sistema">
        
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Configurações fixas</strong>
                    <v-spacer></v-spacer>
                    <v-tooltip>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" @click="confirmaSave = true" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Adicionar Imagem</span>
                    </v-tooltip>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="empresa.emp_id" label="Código da empresa" type="number" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_razao_social" label="Razão social" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_fantasia" label="Fantasia" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_cnpj" label="CNPJ" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_email" label="E-mail" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_telefone" label="Telefone" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_logradouro" label="Logradouro" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_cidade" label="Cidade" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_uf" label="UF" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_link" label="Link externo" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_max_profissionais" label="Quantidade de profissionais contratados" type="number" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_liberado_ate_format" label="Contrato liberado até" :color="consts.corNeutraEscura" dense readonly />
                                <v-text-field v-model="empresa.emp_criacao_format" label="Data de criação da empresa na plataforma" :color="consts.corNeutraEscura" dense readonly />
                                <v-checkbox v-model="empresa.emp_agenda_externa" label="Agenda externa?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense readonly />
                                <v-checkbox v-model="empresa.emp_rel_agenda" label="Relatórios da agenda?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense readonly />
                                <v-checkbox v-model="empresa.emp_rel_financeiro" label="Relatórios do financeiro?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense readonly />
                                <v-checkbox v-model="empresa.emp_integracao_erp" label="Integração com ERP?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense readonly />
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-alert dense type="info" class="ml-2">
                                Caso deseja atualizar o plano atual da empresa, entre em contato pelo email <strong>codezz@codezz.com.br</strong>
                            </v-alert>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- //TODO: não mostrar essas informações até que elas não sejam implementadas no sistema -->
            <v-expansion-panel v-if="false">
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Configurações</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-text>
                            <v-form>
                                <v-text-field v-model="empresa.emp_horas_agendamento" label="Limite para marcar agendamento (horas)" type="number" :color="consts.corNeutraEscura" dense />
                                <v-text-field v-model="empresa.emp_horas_cancelamento" label="Limite para cancelar agendamento (horas)" type="number" :color="consts.corNeutraEscura" dense />
                                <v-textarea v-model="empresa.emp_aviso_agendamento" label="Aviso apresentado ao usuário ao marcar o agendamento" :color="consts.corNeutraEscura" dense />
                                <v-textarea v-model="empresa.emp_aviso_cancelamento" label="Aviso apresentado ao usuário ao cancelar o agendamento" :color="consts.corNeutraEscura" dense />
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="ml-2 mb-2" @click="atualizarParametrosEmp" :color="consts.corNeutraClara" dark>Salvar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog v-model="confirmaSave" max-width="500" max-height="1000" >
            <v-card>
                <v-card-title></v-card-title> <!-- apenas pra dar o espaçamento -->
                <v-card-text class="text-body-1">
                    <span :style="functions.getCssCorEscura()">
                        Adicione uma imagem:
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-file-input v-model="imagem" label="Clique aqui" accept="image/*" :color="consts.corNeutraEscura" dense prepend-icon="mdi-download" multiple /> 
                    <v-btn class="ml-8 my-2" @click="uploadImagem" :color="consts.corEscura" dark>Salvar imagem</v-btn> 
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'
import { VFileInput } from 'vuetify/lib'

export default {
    name: 'ConfigPage',
    components: {
        PageApp,
        VFileInput
    },
    data() {
        return {
            consts,
            confirmaSave: false,
            imagem: null,
            functions,
            panels: [0],
            empresa: {}
        }
    },
    methods: {
        atualizarParametrosEmp() {
            const pBody = {
                emp_horas_agendamento: this.empresa.emp_horas_agendamento,
                emp_horas_cancelamento: this.empresa.emp_horas_cancelamento,
                emp_aviso_agendamento: this.empresa.emp_aviso_agendamento,
                emp_aviso_cancelamento: this.empresa.emp_aviso_cancelamento
            }

            axios.put(`${consts.baseApiUrl}/config/empresa`, pBody)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.loadConfig()

                    this.panels = [0]
                })
                .catch(functions.showError)
        },
        loadConfig() {
            axios.get(`${consts.baseApiUrl}/config/empresa`)
                .then(r => {
                    this.empresa = r.data.data

                    this.empresa.emp_liberado_ate_format = functions.getDataString(this.empresa.emp_liberado_ate)
                    this.empresa.emp_criacao_format = functions.getDataString(this.empresa.emp_criacao)
                })
                .catch(functions.showError)
        },
        uploadImagem() {
            if (this.imagem && this.imagem.length > 0) {
                const arquivo = this.imagem[0]
                
                if (arquivo.name.endsWith('.jpeg') || arquivo.name.endsWith('.png') || arquivo.name.endsWith('.jpg')){
                    const size = arquivo.size / 1024 
                    if(size > 200) {
                        return functions.showError('Arquivo inválido, tamanho maior que 200 KB') 
                    }
                } else {
                    return functions.showError('Arquivo não é uma imagem válida')
                }
                const formData = new FormData()
                formData.append('file', arquivo)
                axios.put(`${consts.baseApiUrl}/config/empresa/adicionar_imagem`, formData)
                    .then(r => {
                        if (r) {
                            functions.showSuccess(r.data.status)
                            this.confirmaSave = false
                        } else {
                            functions.showError('Não foi possível anexar a imagem.')
                        }
                    })
                    .catch(erro => {
                        functions.showError(erro)
                    })
            } else {
                functions.showError('Imagem não informada')
            }
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            if (this.$store.state.profissional.prf_admin == 'S') {
                this.loadConfig()
            } else {
                this.$router.push({
                    name: 'no-permission'
                })
            }
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>