<template>
    <PageApp namePage="profissional-page" title="Página do profissional" subtitle="Seja bem vindo, "
     :subtitleStrong="$store.state.profissional.prf_nome">

        <v-col>
            
            <!-- Empresa bloqueada (2) -->
            <v-alert v-if="tipoBloqueio == 2" dense type="error">
                A empresa está bloqueada, {{ consts.msgContatoCodezz }}
            </v-alert>

            <!-- Empresa liberada, porém mostra mensagem de aviso (1) = Aviso é apresentado com 5 dias de antecedência -->
            <v-alert v-if="tipoBloqueio == 1" dense type="warning">
                Atenção, a empresa será bloqueada {{ this.diasBloqueio == 0 ? 'amanhã' : `em ${this.diasBloqueio} dia(s)` }}, {{ consts.msgContatoCodezz }}
            </v-alert>

            <!-- Conteúdo da página principal do profissional -->
            <v-expansion-panels v-if="tipoBloqueio != 2" v-model="panels" multiple>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Notificações</strong>
                        <v-spacer></v-spacer>
                        <v-btn @click="loadNotificacoes" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-data-table class="tabela-notificacoes" hide-default-footer :headers="colunasNotificacoes" :items="notificacoes" 
                         no-data-text="Nenhuma notificação para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                            <template v-slot:[`item.not_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.not_data_hora, true) }}
                            </template>
                            <template v-slot:[`item.agd_data_hora`]="{ item }">
                                {{ functions.getDataHoraFormatada(item.agd_data_hora, true) }}
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Agenda do dia</strong>
                        <v-spacer></v-spacer>
                        <v-btn @click="loadPagina" @click.prevent.stop class="ml-4" icon max-width="40" x-small>
                            <v-icon>mdi-reload</v-icon>
                        </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-data-table class="tabela-atendimentos" hide-default-footer :headers="colunasAgendaDia" :items="agendaDia" :item-class="getStyleRow"
                            no-data-text="Nenhum atendimento para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}"
                        >
                            <template v-slot:[`item.data_agenda`]="{ item }">
                                {{ functions.getDataHoraString(item.data_agenda) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <div v-if="!item.agd_id && item.age_ativo == 'S'">
                                    Horário livre
                                </div>
                                <div v-if="!item.agd_id && item.age_ativo == 'N'">
                                    Horário bloqueado
                                </div>
                                <v-btn v-if="item.agd_id" @click="openAgendamento(item)" :color="consts.corNeutraClara" x-small dark>
                                    Agendamento
                                </v-btn>
                            </template>
                        </v-data-table>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-col>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import functions from '@/globalFiles/functions'
import consts from '@/globalFiles/consts'
import axios from 'axios'

export default {
    name: 'ProfissionalPage',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            panels: [1],
            tipoBloqueio: 2, //0=Liberado; 1=Aviso; 2=Bloqueado
            diasBloqueio: 0,
            agendaDia: [],
            notificacoes: [],
            colunasAgendaDia: [
                {
                    text: 'Data/Hora',
                    value: 'data_agenda'
                },
                {
                    text: 'Serviço',
                    value: 'nome_servico'
                },
                {
                    text: 'Cliente',
                    value: 'nome_cliente'
                },
                {
                    text: 'Agendamento',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            colunasHorarios: [
                {
                    text: 'Data/Hora',
                    value: 'age_data_hora'
                }
            ],
            colunasNotificacoes: [
                {
                    text: 'Data/Hora',
                    value: 'not_data_hora'
                },
                {
                    text: 'Serviço',
                    value: 'srv_titulo'
                },
                {
                    text: 'Agendamento',
                    value: 'agd_data_hora'
                },
                {
                    text: 'Cliente',
                    value: 'usr_nome'
                },
                {
                    text: 'Status',
                    value: 'not_status'
                }
            ]
        }
    },
    methods: {
        getStyleRow(item) {
            if (item.age_ativo == 'N') {
                return 'style_desativado'
            } else if (item.agd_id > 0) {
                return 'style_agendado'
            } else {
                return 'style_normal'
            }
        },
        loadNotificacoes() {
            this.notificacoes = []
            axios.get(`${consts.baseApiUrl}/notificacoes`)
                .then(r => {
                    this.notificacoes = r.data.data
                })
                .catch(functions.showError)
        },
        loadPagina() {
            this.agendaDia = []

            const dataAtual = new Date()
            const d = functions.getDataWork(dataAtual)

            axios.get(`${consts.baseApiUrl}/agenda/${d}`)
                .then(r => {
                    this.agendaDia = r.data.data.filter(el => {
                        const dataAgenda = new Date(el.data_agenda)
                        return dataAgenda > dataAtual
                    })
                })
                .catch(functions.showError)
        },
        openAgendamento(item) {
            this.$router.push({
                name: 'agendamento',
                params: {
                    agdId: item.agd_id
                }
            })
        },
    },
    async mounted() {
        if (this.$store.state.prfInformado) {
            const empPrf = {
                prf_id: this.$store.state.profissional.prf_id,
                emp_id: this.$store.state.profissional.emp_id
            }

            this.tipoBloqueio = 2 //Bloqueado
            this.diasBloqueio = 0

            await axios.put(`${consts.baseApiUrl}/empresa_liberada`, empPrf)
                .then(res => {
                    if (res.data.aviso) {
                        this.tipoBloqueio = 1 //Aviso
                        this.diasBloqueio = res.data.dias_aviso
                    } else if (res.data.liberado) {
                        this.tipoBloqueio = 0 //Liberado
                    } else {
                        this.tipoBloqueio = 2 //Bloqueado
                    }
                })
                .catch(functions.showError)
            
            if (this.tipoBloqueio != 2) {
                this.loadPagina()
                this.loadNotificacoes()
            }
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>

</style>