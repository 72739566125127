    <template>
        <PageApp namePage="cad-agenda" title="Cadastro da agenda" subtitle="Cadastro da agenda do profissional da empresa">
            
            <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Agenda: {{ functions.getDataString(diaSelec) }}</strong>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.prevent.stop icon max-width="40" x-small>
                                    <v-icon>mdi-information-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Você pode alterar a data de liberação da agenda no cadastro do profissional</span>
                        </v-tooltip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-sheet class="text-center">
                            <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.prevMonth(diaSelec)">
                                <v-icon small>mdi-chevron-left</v-icon>
                            </v-btn>
                            <span> {{ functions.getMesExtenso(diaSelec) }} </span>
                            <v-btn fab text small color="grey darken-2" @click="diaSelec = functions.nextMonth(diaSelec)">
                                <v-icon small>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-sheet>
                        <v-sheet class="mt-4" height="500">
                            <v-calendar v-model="diaSelec" type="month" :weekday-format="functions.getDiaTraduzidoCalendario"> 
                                <template v-slot:day="{ date }">
                                    <v-row class="mt-1 justify-space-around">
                                        <template v-if="(calendario.indexOf(date) != -1) && (date != diaSelec)">
                                            <v-icon :color="consts.corAgendaDesmarcada">
                                                mdi-circle
                                            </v-icon>
                                        </template>
                                        <template v-if="date == diaSelec">
                                            <v-icon :color="consts.corNeutraEscura">
                                                mdi-circle
                                            </v-icon>
                                        </template>
                                    </v-row>
                                </template>
                            </v-calendar>
                        </v-sheet>

                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header :style="functions.getCssCorEscura()">
                        <strong>Horários: {{ functions.getDataString(diaSelec) }}</strong>
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="abrirBloquearHorarios" @click.prevent.stop icon max-width="40" x-small>
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>        
                            </template>
                            <span>Bloquear horários</span>
                        </v-tooltip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-data-table class="tabela-listagem" hide-default-footer :headers="colunas" :items="horarios" 
                            :item-class="getStyleRow" no-data-text="Nenhuma agenda cadastrada para o dia" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}"
                        >
                            <template v-slot:[`item.data_agenda`]="{ item }">
                                {{ functions.getDataHoraString(item.data_agenda) }}
                            </template>
                            <template v-slot:[`item.btnActions`]="{ item }">
                                <v-tooltip v-if="!item.agd_id" top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" v-on="on" @click.prevent.stop icon max-width="40" x-small>
                                            <v-icon>mdi-information-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Para bloquear/liberar horários do dia, utilize a opção "Bloquear horários" acima</span>
                                </v-tooltip>
                                <v-btn v-if="item.agd_id" @click="openAgendamento(item)" :color="consts.corNeutraClara" x-small dark>
                                    Agendamento
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <!-- Dialog para cancelar todos os horários do dia -->
            <v-dialog v-model="mostrarBloquearHorarios" max-width="1000px" max-heigth="800px">
                <v-card>
                    <v-card-title>Bloquear horários da agenda</v-card-title>
                    <v-tabs v-model="tab" :color="consts.corNeutraEscura" class="px-4">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-1">
                            Novo bloqueio
                        </v-tab>
                        <v-tab href="#tab-2">
                            Bloqueios ativos
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item key="tab1" value="tab-1">
                            <v-card flat class="px-12 py-4">
                                <v-form>
                                    <v-text-field v-model="bloqueioHor.blq_data_inicial" v-mask="'##/##/#### ##:##:##'" label="Data inicial" :color="consts.corNeutraEscura" dense />
                                    <v-text-field v-model="bloqueioHor.blq_data_final" v-mask="'##/##/#### ##:##:##'" label="Data final" :color="consts.corNeutraEscura" dense />
                                </v-form>
                                <v-row class="mt-3">
                                    <v-btn class="ml-2 mb-2" @click="saveBloqueioHorario" :color="consts.corNeutraClara" dark>Salvar</v-btn>
                                    <v-btn class="ml-2 mb-2" @click="mostrarBloquearHorarios = false" :color="consts.corClara">Cancelar</v-btn>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="tab2" value="tab-2">
                            <v-card flat class="px-12 pb-4">
                                <v-data-table class="tabela-listagem" hide-default-footer :headers="colunasHorariosBloq" :items="listHorariosBloq"
                                    no-data-text="Nenhum bloqueio ativo para o profissional" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}"
                                >
                                    <template v-slot:[`item.blq_data_inicial`]="{ item }">
                                        {{ functions.getDataHoraString(item.blq_data_inicial) }}
                                    </template>
                                    <template v-slot:[`item.blq_data_final`]="{ item }">
                                        {{ functions.getDataHoraString(item.blq_data_final) }}
                                    </template>
                                    <template v-slot:[`item.btnActionsHorariosBloq`]="{ item }">
                                        <v-btn @click="removerBloqueioHor(item)" :color="consts.corNeutraClara" x-small dark>
                                            Liberar horários
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-dialog>

        </PageApp>
    </template>

    <script>
    import PageApp from '@/components/templates/PageApp'
    import consts from '@/globalFiles/consts'
    import functions from '@/globalFiles/functions'
    import axios from 'axios'

    export default {
        name: 'CadAgenda',
        components: {
            PageApp
        },
        data() {
            return {
                consts,
                functions,
                diaSelec: '',
                mostrarBloquearHorarios: false,
                panels: [0, 1],
                calendario: [],
                horarios: [],
                colunas: [
                    {
                        text: 'Data/Hora',
                        value: 'data_agenda'
                    },
                    {
                        text: 'Serviço',
                        value: 'nome_servico'
                    },
                    {
                        text: 'Cliente',
                        value: 'nome_cliente'
                    },
                    {
                        text: 'Ações',
                        value: 'btnActions',
                        sortable: false
                    }
                ],
                tab: null,
                bloqueioHor: {},
                listHorariosBloq: [],
                colunasHorariosBloq: [
                    {
                        text: 'Data/hora inicial',
                        value: 'blq_data_inicial'
                    },
                    {
                        text: 'Data/hora final',
                        value: 'blq_data_final'
                    },
                    {
                        text: 'Ações',
                        value: 'btnActionsHorariosBloq',
                        sortable: false
                    }
                ]
            }
        },
        methods: {
            getStyleRow(item) {
                if (item.age_ativo == 'N') {
                    return 'style_desativado'
                } else if (item.agd_id > 0) {
                    return 'style_agendado'
                } else {
                    return 'style_normal'
                }
            },
            loadAgenda() {
                this.calendario = []
                this.diaSelec = ''

                axios.get(`${consts.baseApiUrl}/agenda`)
                    .then(r => {
                        r.data.data.forEach(el => {

                            if (this.diaSelec == '') {
                                this.diaSelec = el.substr(0, 10)
                                this.loadHorarios()
                            }

                            this.calendario.push(el.substr(0, 10)) // 10 primeiras posições: yyyy-mm-dd
                        })
                    })
                    .catch(functions.showError)
            },
            loadHorarios() {
                this.horarios = []

                axios.get(`${consts.baseApiUrl}/agenda/${this.diaSelec} 00:00:00`)
                    .then(r => {
                        this.horarios = r.data.data
                    })
                    .catch(functions.showError)
            },
            openAgendamento(item) {
                this.$router.push({
                    name: 'agendamento',
                    params: {
                        agdId: item.agd_id
                    }
                })
            },
            loadBloqueioHorarios() {
                axios.get(`${consts.baseApiUrl}/bloqueio_agenda`)
                    .then(r => {
                        this.listHorariosBloq = r.data.data
                    })
                    .catch(functions.showError)
            },
            abrirBloquearHorarios() {
                this.mostrarBloquearHorarios = true
                delete this.bloqueioHor.blq_data_inicial
                delete this.bloqueioHor.blq_data_final
                this.loadBloqueioHorarios()
            },
            saveBloqueioHorario() {
                axios.post(`${consts.baseApiUrl}/bloqueio_agenda`, this.bloqueioHor)
                    .then(() => {
                        this.mostrarBloquearHorarios = false
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            },
            removerBloqueioHor(item) {
                axios.delete(`${consts.baseApiUrl}/bloqueio_agenda/${item.blq_id}`)
                    .then(() => {
                        this.mostrarBloquearHorarios = false
                        this.$toasted.global.msgSuccess()
                        this.loadHorarios()
                    })
                    .catch(functions.showError)
            }
        },
        watch: {
            diaSelec() {
                this.loadHorarios();
            }
        },
        mounted() {
            if (this.$store.state.prfInformado) {
                this.loadAgenda()
            } else {
                this.$router.push({
                    name: 'home'
                })
            }
        }
    }
    </script>

    <style>
        .style_desativado {
            background-color: rgb(255, 0, 0, .2) !important;
            color: black;
        }
        .style_agendado {
            background-color: rgb(0, 255, 0, .2) !important;
            color: black;
        }
        .style_normal {
            color: black;
        }
    </style>