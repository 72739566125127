<template>
    <v-footer id="footer-app" :color="consts.corHeaderFooter">
        <v-row class="d-flex my-1" justify="center">
            <span class="d-none d-sm-flex" :style="functions.getCssCorClara()">
                Desenvolvido por&ensp;
            </span> 
            <a class="site-codezz" href :style="functions.getCssCorClara()"
             @click.prevent="functions.openSiteUrl(consts.siteCodezz)">
                <strong>Codezz Soluções em Tecnologia</strong>
            </a>
        </v-row>
    </v-footer>
</template>

<script>
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'

export default {
    name: 'FooterApp',
    data() {
        return {
            consts,
            functions
        }
    }
}
</script>

<style>
    .site-codezz {
        text-decoration: none;
    }
</style>