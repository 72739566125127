<template>
    <PageApp namePage="cad-servicos" title="Cadastro de serviços" subtitle="Cadastro dos serviços do profissional da empresa">

        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Formulário</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="servico.srv_titulo" label="Título" :color="consts.corNeutraEscura" dense />
                        <v-textarea v-model="servico.srv_descricao" label="Descrição" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="servico.srv_preco" label="Preço" type="number" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="servico.srv_horas" label="Horas" type="number" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="servico.srv_minutos" label="Minutos" type="number" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="servico.srv_ordem" label="Ordem" type="number" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-model="servico.srv_qtd_clientes" label="Quantidade de clientes para considerar o horário cheio" type="number" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-if="empresa.emp_integracao_erp == 'S'" v-model="servico.srv_id_conta_bancaria_codezz" label="ID conta bancária (integração ERP)" type="number" :color="consts.corNeutraEscura" dense />
                        <v-text-field v-if="empresa.emp_integracao_erp == 'S'" v-model="servico.srv_id_grupo_conta_codezz" label="ID grupo de conta (integração ERP)" type="number" :color="consts.corNeutraEscura" dense />
                        <v-checkbox v-model="servico.srv_ativo" label="Ativo?" true-value="S" false-value="N" :color="consts.corNeutraEscura" dense />
                    </v-form>
                    <v-row class="mt-3">
                        <v-btn class="ml-2 mb-2" v-if="mode === 'save'" @click="save" :color="consts.corNeutraClara" dark>Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'remove'" @click="remove" :color="consts.corNeutraEscura" dark>Excluir</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset" :color="consts.corClara">Cancelar</v-btn>
                    </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header :style="functions.getCssCorEscura()">
                    <strong>Listagem</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-listagem" hide-default-footer :headers="colunas" :items="servicos"
                     no-data-text="Nenhum serviço cadastrado" :items-per-page="-1" :header-props="{sortByText: 'Ordenar por...'}">
                        <template v-slot:[`item.btnActions`]="{ item }">
                            <v-btn class="mr-2" @click="loadServico(item)" :color="consts.corNeutraClara" x-small dark>Editar</v-btn>
                            <v-btn @click="loadServico(item, 'remove')" :color="consts.corNeutraEscura" x-small dark>Remover</v-btn>
                        </template>
                    </v-data-table>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/globalFiles/consts'
import functions from '@/globalFiles/functions'
import axios from 'axios'

export default {
    name: 'CadServicos',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            functions,
            panels: [0, 1],
            mode: 'save',
            empresa: {},
            servico: {},
            servicos: [],
            colunas: [
                {
                    text: 'Código',
                    value: 'srv_iid'
                },
                {
                    text: 'Título',
                    value: 'srv_titulo'
                },
                {
                    text: 'Ativo?',
                    value: 'srv_ativo'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        fetchEmpresa(){
            axios.get(`${consts.baseApiUrl}/config/empresa`)
                .then(response => {
                    this.empresa = response.data.data
                })
                .catch(error => {
                    this.empresa.emp_integracao_erp = 'N' 
                    console.error(error) // não mostra o erro em tela!
                })
        },
        save() {
            const method = this.servico.srv_id ? 'put' : 'post'
            const id = this.servico.srv_id ? `/${this.servico.srv_id}` : ''

            this.servico.prf_id = this.$store.state.profissional.prf_id

            if (!this.servico.srv_ativo) {
                this.servico.srv_ativo = 'N'
            }

            axios[method](`${consts.baseApiUrl}/servicos${id}`, this.servico)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        remove() {
            const id = this.servico.srv_id
            axios.delete(`${consts.baseApiUrl}/servicos/${id}`)
                .then(() => {
                    this.$toasted.global.msgSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.servico = {}
            this.loadServicos()
        },
        loadServicos() {
            axios.get(`${consts.baseApiUrl}/servicos`)
                .then(r => {
                    this.servicos = r.data.data
                })
                .catch(functions.showError)
        },
        loadServico(servico, mode = 'save') {
            this.mode = mode
            this.servico = { ...servico }
        }
    },
    mounted() {
        if (this.$store.state.prfInformado) {
            this.loadServicos()
            this.fetchEmpresa()
        } else {
            this.$router.push({
                name: 'home'
            })
        }
    }
}
</script>

<style>
    .tabela-listagem tr:nth-of-type(even) {
        background-color: rgba(0, 0, 0, .03);
    }
</style>