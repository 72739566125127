import Vue from 'vue'
import VueRouter from 'vue-router'

//Páginas iniciais
import HomePage from '@/components/pages/HomePage'
import NovaEmpresa from '@/components/pages/NovaEmpresa'
import NoPermissionPage from '@/components/pages/NoPermissionPage'

//Páginas do profissional
import LoginProfissional from '@/components/pages/profissional/LoginProfissional'
import ProfissionalPage from '@/components/pages/profissional/ProfissionalPage'
import CadServicos from '@/components/pages/profissional/CadServicos'
import CadHorarios from '@/components/pages/profissional/CadHorarios'
import CadProfissionais from '@/components/pages/profissional/CadProfissionais'
import CadAgenda from '@/components/pages/profissional/CadAgenda'
import CadAgendamentos from '@/components/pages/profissional/CadAgendamentos'
import AgendamentoPage from '@/components/pages/profissional/AgendamentoPage'
import ConfigPage from '@/components/pages/profissional/ConfigPage'

//Páginas do usuário
import LoginUsuario from '@/components/pages/usuarios/LoginUsuario'
import UsuarioPage from '@/components/pages/usuarios/UsuarioPage'
import SelecionarEmpresa from '@/components/pages/usuarios/SelecionarEmpresa'
import ConsAgendamentos from '@/components/pages/usuarios/ConsAgendamentos'
import NovoAgendamento from '@/components/pages/usuarios/NovoAgendamento'

//Relatórios (listagem)
import RelListClientes from '@/components/pages/relatorios/listagens/RelListClientes'
import RelListNotificacoes from '@/components/pages/relatorios/listagens/RelListNotificacoes'
import RelAgendaConcluidos from '@/components/pages/relatorios/agenda/RelAgendaConcluidos'
import RelAgendaAusentes from '@/components/pages/relatorios/agenda/RelAgendaAusentes'
import RelAgendaCancelados from '@/components/pages/relatorios/agenda/RelAgendaCancelados'
import RelAgendaContagem from '@/components/pages/relatorios/agenda/RelAgendaContagem'
import RelAgendaClientes from '@/components/pages/relatorios/agenda/RelAgendaClientes'
import RelFinTotalMes from '@/components/pages/relatorios/financeiro/RelFinTotalMes'
import RelFinFluxoCaixa from '@/components/pages/relatorios/financeiro/RelFinFluxoCaixa'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/nova_empresa',
        name: 'novaEmpresa',
        component: NovaEmpresa
    },
    {
        path: '/login_profissional',
        name: 'loginProfissional',
        component: LoginProfissional
    },
    {
        path: '/profissional',
        name: 'profissional',
        component: ProfissionalPage
    },
    {
        path: '/servicos',
        name: 'servicos',
        component: CadServicos
    },
    {
        path: '/horarios',
        name: 'horarios',
        component: CadHorarios
    },
    {
        path: '/profissionais',
        name: 'profissionais',
        component: CadProfissionais
    },
    {
        path: '/agenda',
        name: 'agenda',
        component: CadAgenda
    },
    {
        path: '/agendamentos',
        name: 'agendamentos',
        component: CadAgendamentos
    },
    {
        path: '/agendamento',
        name: 'agendamento',
        component: AgendamentoPage,
        props: true
    },
    {
        path: '/consulta_agendamentos',
        name: 'consultaAgendamentos',
        component: ConsAgendamentos
    },
    {
        path: '/login_usuario',
        name: 'loginUsuario',
        component: LoginUsuario
    },
    {
        path: '/usuario',
        name: 'usuario',
        component: UsuarioPage
    },
    {
        path: '/selecionar_empresa',
        name: 'selecionarEmpresa',
        component: SelecionarEmpresa
    },
    {
        path: '/agendar/:link',
        name: 'novoAgendamento',
        component: NovoAgendamento
    },
    {
        path: '/config',
        name: 'config',
        component: ConfigPage
    },
    {
        path: '/nopermission',
        name: 'no-permission',
        component: NoPermissionPage
    },
    {
        path: '/rel_list_clientes',
        name: 'relListClientes',
        component: RelListClientes
    },
    {
        path: '/rel_agenda_concluidos',
        name: 'relAgendaConcluidos',
        component: RelAgendaConcluidos
    },
    {
        path: '/rel_agenda_cancelados',
        name: 'relAgendaCancelados',
        component: RelAgendaCancelados
    },
    {
        path: '/rel_agenda_contagem',
        name: 'relAgendaContagem',
        component: RelAgendaContagem
    },
    {
        path: '/rel_fin_total_mes',
        name: 'relFinTotalMes',
        component: RelFinTotalMes
    },
    {
        path: '/rel_fin_fluxo_caixa',
        name: 'relFinFluxoCaixa',
        component: RelFinFluxoCaixa
    },
    {
        path: '/rel_agenda_ausentes',
        name: 'relAgendaAusentes',
        component: RelAgendaAusentes
    },
    {
        path: '/rel_agenda_clientes',
        name: 'relAgendaClientes',
        component: RelAgendaClientes
    },
    {
        path: '/rel_list_notificacoes',
        name: 'relListNotificacoes',
        component: RelListNotificacoes
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
