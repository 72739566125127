//Constantes que podem ser utilizadas/reaproveitadas no sistema inteiro

const producaoCodezz = true

export default {

    corClara:        'rgb(226, 232, 240)', //#4A5568
    corEscura:       'rgb(74,   85, 104)', //#E2E8F0
    corNeutraClara:  'rgb(66,  153, 225)', //#2b6cb0
    corNeutraEscura: 'rgb(43,  108, 176)', //#4299e1
    corHeaderFooter: 'rgb(27,   25,  77)', //#1b194d
    corAgendaDesmarcada: 'rgb(163, 207, 255)', //#a3cfff

    baseApiUrl: producaoCodezz ? 'https://api-agendamentos.codezz.com.br:3002' : 'http://192.168.100.245:3002',

    userKeyProfissional: '__agendamentos_profissional_codezz',
    userKeyUsuario: '__agendamentos_usuario_codezz',

    siteCodezz: 'https://www.codezz.com.br',
    siteAgendamentosCodezz: producaoCodezz ? 'https://agendamentos.codezz.com.br' : 'http://192.168.100.245:8081',

    msgContatoCodezz: 'entre em contato com a Codezz (codezz@codezz.com.br)'
}
